@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../assets/fonts/Inter-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('../assets/fonts/Inter-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('../assets/fonts/Inter-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('../assets/fonts/Inter-Bold.woff2') format('woff2');
  font-display: swap;
}
